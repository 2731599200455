body {
  background-color: #7cc6fe;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: "Verdana", cursive, sans-serif;
}

.image-question {
	height: 50%;
	width: 50%;
}

.image-error {
	height: 50%;
	width: 50%;
}

.image-logo {
  width : 130px;
  height: 70px;
}


.Home {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.app {
  background-color: #252d4a;
  border-radius: 15px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
}

.Center-Content {
  display: flex;
  justify-content: center;
}

.Error-Content {
  display: flex;
  justify-content: center;
  text-align: center;
}

.form-section {
  padding: 1em;
}

.score-section {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.start-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.survey-title {
  justify-content: center;
  text-align: center;
}

/* QUESTION/TIMER/LEFT SECTION */
.question-section {
  width: 100%;
  position: relative;
}

.question-count {
  margin-bottom: 20px;
}

.question-count span {
  font-size: 28px;
}

.question-text {
  margin-bottom: 12px;
}

.timer-text {
  background: rgb(230, 153, 12);
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
  border: 5px solid rgb(255, 189, 67);
  border-radius: 15px;
  text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.correct {
  background-color: #2f922f;
}

.incorrect {
  background-color: #ff3333;
}

label {
  font-weight: 600;
}

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

hr.solid {
  border-top: 8px solid #bbb;
  border-radius: 5px;
}

.login-card {
  min-height: 60vh;
}

.Table-Content {
  width: 75vw;
  height: 50vh;
}

.NavBar-Content {
  background-color: #252d4a;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link-Color{
  color: white;
}

.navbar-toggler-icon{
  background-image: url("../assets/toggler.svg") !important;
}

.input-search{
        height: 34px;
        width: 200px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
}

.search-component {
  display: flex;
  justify-content:end;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}